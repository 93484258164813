import React, {useState} from 'react';
import {styled} from '@mui/material/styles';
import {FixedSizeList as List} from "react-window";
import Grid from "@mui/material/Grid2";
import BusinessIcon from '@mui/icons-material/Business';
import SearchBar from "../../../Controls/SearchBar";
import PropTypes from "prop-types";
import {Alert, Typography} from "@mui/material";
import search from "../../../../store/actions/SearchDataService";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import DataOriginChip from "../../TemplatesV2/Components/DataOriginChip";
import i18n from "../../../../i18n/i18n";
import Loading from "../../../Animation/Loading";
import {useAsync} from "react-async-hook";

const PREFIX = 'SearchLocationAdressDialog';

const classes = {
    rowEven: `${PREFIX}-rowEven`,
    rowOdd: `${PREFIX}-rowOdd`,
    emptyContainer: `${PREFIX}-emptyContainer`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
    [`& .${classes.rowEven}`]: {
        background: "#F5F5F5",
        padding: '20px',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        cursor: 'pointer',
        "& .MuiSvgIcon-root": {color: "rgba(0, 0, 0, 0.54)",},
        '&:hover': {
            backgroundColor: '#D2D8DD',
            "& .MuiSvgIcon-root": {color: "#203A56",},
        },
    },

    [`& .${classes.rowOdd}`]: {
        background: "#FFFFFF",
        padding: '20px',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        cursor: 'pointer',
        "& .MuiSvgIcon-root": {color: "rgba(0, 0, 0, 0.54)",},
        '&:hover': {
            backgroundColor: '#D2D8DD',
            "& .MuiSvgIcon-root": {color: "#203A56",},
        }
    },

    [`& .${classes.emptyContainer}`]: {
        background: "#F5F5F5",
        padding: '20px',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        height: '100px',
        marginTop: '20px',
    }
}));

const debouncedSearch = AwesomeDebouncePromise(search, 700);

export default function SearchLocationAddressDialog(props) {
    const [searchInput, setSearchInput] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    // =====================
    // Handle Selected Company
    // =====================
    const onItemClickHandler = (selectedLocation) => {
        props.handleLocationSelected(selectedLocation)
    }

    // =====================
    // Handle Search
    // =====================
    const asyncSearch = useAsync(async () => {
        if (searchInput.length < 3 ) return [];

        const result = await debouncedSearch({
            filterValue: searchInput,
            filterField: props.name,
            dataSources: props.dataSources,
            uniqueField: props.name,
        });
        setFilteredData(result);
    }, [searchInput]);

    const handleChange = (text) => {
        setSearchInput(text)
    };

    const clearFilter = () => {
        setFilteredData([]);
    }

    // =====================
    // Company rows
    // =====================
    const list = filteredData;
    const renderRow = ({index, style}) => (
        <>
            <div key={list[index].id} style={style} className={index % 2 === 0 ? classes.rowEven : classes.rowOdd}
                 onClick={onItemClickHandler.bind(this, list[index])}>
                <Grid container>
                    <Grid size={{xs: 2, md: 1}}>
                        <BusinessIcon/>
                    </Grid>
                    <Grid size={{xs: 8, md: 6}}>
                        <Typography variant="body2"><strong>{list[index].name}</strong></Typography>
                        <Typography variant="body2">{list[index].street_name} {list[index].building_number}{list[index].building_number_addition}</Typography>
                        <Typography variant="body2">{list[index].postal_zone || list[index].postal_code}, {list[index].city_name}</Typography>
                        <Typography variant="body2">{list[index].vihb_full}</Typography>
                    </Grid>
                    <Grid size={{xs: 2, md: 5}}>
                        <DataOriginChip origin={list[index].entity}/>
                    </Grid>
                </Grid>
            </div>
        </>
    );

    return (
        <Root>
            <Grid container>
                <Grid size={12}>
                    <SearchBar
                        handleChange={handleChange}
                        clearFilter={clearFilter}
                    />
                </Grid>
            </Grid>
            {
                !filteredData.length && searchInput.length < 3 ? (
                    <div className={classes.emptyContainer}>
                        <Typography variant="body2">{i18n.t('TYPE_AT_LEAST_TO_SEARCH')}</Typography>
                    </div>
                ) : null
            }
            {
                asyncSearch.loading ? (
                    <Loading size={120} margin={'300px 520px'}/>
                ) : (
                    searchInput && searchInput.length > 3 && !filteredData.length ? (
                            <Alert severity="info"  style={{marginBottom:'658px'}}>
                                {i18n.t('NO_LOCATION_FOUND')}
                            </Alert>
                        )
                        :
                        (
                            <List
                                style={{overflowX: 'hidden'}}
                                height={700}
                                itemCount={list ? list.length : null}
                                itemSize={120}
                            >
                                {renderRow}
                            </List>
                        )
                )
            }
            <br/>
        </Root>
    )
        ;
}

SearchLocationAddressDialog.propTypes = {
    handleLocationSelected: PropTypes.func.isRequired,
    dataSources: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
};